import React from 'react'
//import Select from 'react-select'
import countryList from 'react-select-country-list'

//import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { QuestionCircleFill } from './icons_lib'
import { iconPath } from './icons_lib'

// generic functions ad constants used across app

// model constants
// note: the icon attributes is the name of the object attribute defined in icon_libs , not the actual
// name of the icon file. All icons used here must be pipeline icons as defined in icon_libs
export const Vessel_atts = {
    sailboat: {
        icon: 'sailboat_grn',
        ais_code: 36,
    },
    powerboat: {
        icon: 'powerb_blu',
        ais_code: 37,
    },
    multihull: {
        icon: 'sailboat_grn',
        ais_code: 36,
    },
    passenger: {
        icon: 'powerb_yel',
        ais_code: 60,
    },
    cargo: {
        icon: 'powerb_yel',
        ais_code: 70,
    },
    tanker: {
        icon: 'powerb_yel',
        ais_code: 80,
    },    
    towing: {
        icon: 'powerb_yel',
        ais_code: 31,
    },
    fishing_boat: {
        icon: 'powerb_yel',
        ais_code: 30,
    },
    other: {
        icon: 'powerb_yel',
        ais_code: 0
    }
}
export const Vessel_status={
    0: { icon: 'logimg_N', name: 'Vessel under way' },
    1: { icon: 'logimg_U', name: 'Vessel not under way' },
    2: { icon: 'logimg_C', name: 'Vessel checked-in at known POI' },
}
export const Place_atts = {
    0: {
        name: 'Marina',
        icon: 'marina_grn',
    },
    1: {
        name: 'Anchorage',
        icon: 'anchor_blu',
    },
    2: {
        name: 'Dock/Pier',
        icon: 'pier_yel',
    },
    3: {
        name: 'Yacht Club',
        icon: 'club_pur',
    },
    4: {
        name: 'Mooring',
        icon: 'moorings_tur',
    },
    5: {
        name: 'Fuel station',
        icon: 'fuelpump_red',
    },
    6: {
        name: 'Dry dock',
        icon: 'ramp_brn',
    },
    7: {
        name: 'Launch Ramp',
        icon: 'ramp_brn',
    },
    8: {
        name: 'Hurricane Hole',
        icon: 'hole_per',
    },
    9: {
        name: 'AIS Aton',
        icon: 'aton_gry',
    },
    10: {
        name: 'Commercial Facility',
        icon: 'generic_grn',
    },
    11: {
        name: 'Commercial Facility',
        icon: 'generic_grn',
    },
    12: {
        name: 'Local Weather Reports',
        icon: 'aton_blu',
    },
    13: {
        name: 'Ship Weather Reports',
        icon: 'aton_yel',
    },
    19: {
        name: 'Other POI',
        icon: 'generic_grn',
    },
}

export const VesselTypes = () => {
    // e.g. enumerated values for field vessel.vessel_type, e.g. ['Powerboat, 'Sailboat',....], capitalized
    return Object.keys(Vessel_atts).map(att_key => {
        return capitalizeFirstLetter(att_key)
    })
}

export const aisCodeMapping = () => {
    // looks up ais_code (e.g. 36, 37) mapping to vessel_type
    const aisCodes = {}
    for (const vesselKey in Vessel_atts) {
        const { icon, ais_code } = Vessel_atts[vesselKey]
        if (!aisCodes[ais_code]) {
            aisCodes[ais_code] = []
        }
        aisCodes[ais_code].push(vesselKey)
    }
    return aisCodes
}

// picklist select options

export const VesselTypeOptions = () => {
    // vessel type pick list
    const types = VesselTypes()
    const options = types.map((type, index) => {
        return (
            <option key={index} value={type}>
                {type}
            </option>
        )
    })
    return options
}
export const PlaceTypeOptions = () => {
    // place type pick list
    const options = Object.keys(Place_atts).map((att_key, index) => {
        return (
            <option key={index} value={att_key}>
                {Place_atts[att_key].name}
            </option>
        )
    })
    return options
}
export const CountryOptions = () => {
    // countries picklist
    const countriesObj = countryList().getValueList()
    const options = Object.keys(countriesObj).map((key, index) => {
        return (
            <option key={index} value={key.toUpperCase()}>
                {countriesObj[key]}
            </option>
        )
    })
    return options
}

// default object thumbnails
export function vesselIconName(vessType) {
    const iconKey = vessType && vessType.toLowerCase()
    return Vessel_atts[iconKey] && Vessel_atts[iconKey]['icon']
}
export function vesselIconPath(vess_type) {
    return iconPath(vesselIconName(vess_type))
}
export function vesselThumbnail(obj) {
    // obj can be either vessel or checkin
    if (obj.thumb_url) {
        return '/' + obj.thumb_url
    } else {
        // TODO  this needs to be updated by using a dedicated vessel_type for stinkpots
        // if ( (obj.vessel_type == 'Powerboat' && obj.vessel_loa > 33) || (obj.ais_type == 60 && obj.vessel_loa >= 240) ) {
        if ( obj.stinkpot) {
            return iconPath('powerb_red')
        } else {
            return iconPath(vesselIconName(obj.vessel_type))
        }
    }
}
export function userThumbnail(thumb_url) {
    if (thumb_url) {
        return addSlash(thumb_url)
    } else {
        return iconPath('person')
    }
}
export function placeTypeName(place) {
    const iconKey = place && place.type_enum
    return Place_atts[iconKey] && Place_atts[iconKey]['name']
}
export function placeIconName(place) {
    const iconKey = place && place.type_enum
    return Place_atts[iconKey] && Place_atts[iconKey]['icon']
}
export function placeIconPath(place) {
    return iconPath(placeIconName(place))
}
export function placeThumbnail(place) {
    if (place.thumb_url) {
        // add a slash at the beginning unless it's a full link (http)
        return addSlash(place.thumb_url)
    } else {
        return (
            //iconPath(Place_atts[place.type_enum] && Place_atts[place.type_enum].icon)
            placeIconPath(place)
        )
    }
}
export function postThumbnail(post) {
    if (post && post.thumb_url) {
        return addSlash(post.thumb_url)
    } else {
        return iconPath(`chat_fill`)
    }
}

// general purpose functions
const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
        Simple tooltip
    </Tooltip>
)
export const LinkTt = ({ id, children, title, link="#" }) => {
    // if no children (e.g. called as <LinkTt id={...}  title={..} /> ), display the question mark
    return (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <a href={link} target="_blank">{children ? children : <QuestionCircleFill />}</a>
        </OverlayTrigger>
    )
}
export function delFields(fieldList, obj) {
    // delete multiple fields from an object
    fieldList.forEach(e => delete obj[e])
    return obj
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
export function dateToStr(date) {
    // takes a date object and returns it in YYYY-MM-DDTHH:MM:SS.000Z format
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2) // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`
    return formattedDate
}
export function strToDate(dateStr) {
    const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }
    return new Date(dateStr).toLocaleDateString('en-US', options)
}
export function addSlash(path) {
    // prepends a slash to a path unless it's a url
    if (path && path.slice(0, 4) == 'http') {
        return path
    } else {
        return '/' + path
    }
}
export function truncate_with_dots(text, l) {
    if (text.length > l) {
        return text.slice(0, l) + '....'
    } else {
        return text
    }
}
export function addComma(text, followed = true) {
    return text && followed && text + ', '
}
export function rVal(val, decs) {
    return Math.round(val * 10 ** decs) / 10 ** decs
}
