import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../../../store/user-context'
import axios from 'axios'
import { LinkTt } from '../../../src/helpers'

const DownloadKapFile = props => {
    const userCtx = useContext(UserContext)

    const [zoom, setZoom] = useState(18)

    const handleChange = e => {
        e.preventDefault()
        setZoom(e.target.value)
    }
    // const title = `Download a geo-referenced satellite map of this POI. ${!userCtx.loggedIn &&  '(You must be logged in)'}`
    const title =
        'Download a geo-referenced satellite map of this POI.' +
        (!userCtx.loggedIn ? '(You must be logged in)' : '')

    const zoomLevels = [14, 15, 16, 17, 18, 19, 20, 21].map(
        (zoomLevel, index) => {
            return (
                <option key={index} value={zoomLevel}>
                    {zoomLevel}
                </option>
            )
        }
    )
    const handleSubmit = e => {
        e.preventDefault()

        // method for downloading a file with axios
        $('#ajax-loader').show()
        axios({
            url: `/api/v1/places/download_kap_file/${props.place.id}?zoom=${zoom}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then(resp => {
                $('#ajax-loader').hide()
                alert('Your file is ready for download')
                // create file link in browser's memory
                const href = URL.createObjectURL(resp.data)

                // create "a" HTML element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute(
                    'download',
                    `${props.place.id}_gm_z${zoom}.kap`
                )
                document.body.appendChild(link)
                link.click()

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
            })
            .catch(resp => {})
    }

    return (
        <div className="mx-2">
            <form onSubmit={handleSubmit}>
                <div className="to_left">
                    Download Satellite Map at zoom level:
                    <LinkTt
                        title={title}
                        id="id-1"
                        link="/faq#satellite_maps"
                    />
                </div>

                <div className="to_left mx-2">
                    <select
                        className="form-control px-1 py-0"
                        type="select"
                        name="zoom"
                        onChange={handleChange}
                        defaultValue="18"
                    >
                        {zoomLevels}
                    </select>
                </div>

                <div className="to_left mx-2">
                    <button
                        type="submit"
                        disabled={!userCtx.loggedIn}
                        className="btn btn-secondary btn-sm p-1"
                    >
                        Download
                    </button>
                </div>
            </form>
        </div>
    )
}

export default DownloadKapFile
